import { latLngToCell, cellToLatLng } from "h3-js";
import { kdTree } from "kd-tree-javascript";

function distance(a, b) {
  var lat1 = a.lat,
    lon1 = a.lng,
    lat2 = b.lat,
    lon2 = b.lng;
  var rad = Math.PI / 180;

  var dLat = (lat2 - lat1) * rad;
  var dLon = (lon2 - lon1) * rad;
  var lat1New = lat1 * rad;
  var lat2New = lat2 * rad;

  var x = Math.sin(dLat / 2);
  var y = Math.sin(dLon / 2);

  var aNew = x * x + y * y * Math.cos(lat1New) * Math.cos(lat2New);
  return Math.atan2(Math.sqrt(aNew), Math.sqrt(1 - aNew));
}

export function getKDTree(points, res) {
  const ps = new Set();
  for (var i in points) {
    var id = latLngToCell(points[i].lat, points[i].lng, res);
    var latlng = cellToLatLng(id);
    var p = {
      id: id,
      lat: latlng[0],
      lng: latlng[1],
    };
    ps.add(JSON.stringify(p));
  }

  const uniquePs = [];
  ps.forEach((p) => {
    JSON.parse(p);
    uniquePs.push(JSON.parse(p));
  });

  var tree = new kdTree(uniquePs, distance, ["lat", "lng"]);
  return tree;
}
