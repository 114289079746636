import { React, useState, useEffect, useRef, memo } from "react";
import Globe from "react-globe.gl";
import * as THREE from "three";
import * as d3 from "d3";
import { createGlowMesh } from "./glow-mesh";
import { Vector3 } from "three";
import { getKDTree } from "./nearest";
import { createCloudMaterial } from "./cloud-shader";
import { TextureLoader, DefaultLoadingManager } from "three";
import PuffLoader from "react-spinners/PuffLoader";
import {decode} from 'html-entities';

function polar2Cartesian(lat, lng, relAltitude = 0) {
  const phi = ((90 - lat) * Math.PI) / 180;
  const theta = ((90 - lng) * Math.PI) / 180;
  const r = 1 * (1 + relAltitude);

  return new Vector3(
    r * Math.sin(phi) * Math.cos(theta),
    r * Math.cos(phi),
    r * Math.sin(phi) * Math.sin(theta)
  );
}

function getHexData(globe) {
  return globe.scene().children[3].children[0].children[3].children[0].__data;
}

function setHexposUniform(globe, hexpos) {
  var shaderHexPos = new Vector3(0.01, 0.01, 0.01);

  if (hexpos !== null) {
    shaderHexPos = hexpos;
  }

  globe.scene().children[3].children[0].children[3].children[0].material.uniforms.hexpos.value =
    shaderHexPos;
}

function removeDuplicates(data) {
  const set = new Set();
  const uniqueData = [];
  for (let i in data) {
    var title = data[i].title;
    title = title.split("[")[0];
    if (!set.has(title)) {
      uniqueData.push(data[i]);
      set.add(title);
    }
  }
  return uniqueData;
}

// TODO: Call setIsHexHovered(true) wherever that happens, pass into props on line 55
// TODO: Put source in card
const GlobeComponent = ({ onFocus, onHover, onStart, isMobile, date }) => {
  const globeEl = useRef();
  const [popData, setPopData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const lastOpened = useRef(null);
  const interacted = useRef(false);
  const hoverD = useRef(null);
  const kdTree = useRef(null);
  const loaded = useRef(false);
  const [hexResolution] = useState(2);
  const cloudsTexture = useRef(null);
  const weightColor = d3
    .scaleSequentialSqrt(d3.interpolateBlues)
    .domain([300, 0]);

  const [dimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    if (globeEl.current === undefined) return;
    const globe = globeEl.current;

    let query = "https://bluedot.onrender.com/sunpos/";

    if (date !== null)
    {
      const dateStr = date.format("YYYY-MM-DD");
      query = "https://bluedot.onrender.com/sunpos_date?pubDate="+dateStr;
    }

    fetch(query).then((res) => res.json())
      .then(function (data)
      {
        if (globe.scene().children.length === 7)
        {
          const sunpos = polar2Cartesian(data.sunpos[0], data.sunpos[1]);

          globe.scene().children[5].position.set(sunpos.x, sunpos.y, sunpos.z);

          globe.scene().children[6] = createGlowMesh(
            new THREE.SphereGeometry(globe.getGlobeRadius() * 1.2, 75, 75),
            sunpos
          ); 
        }

        onFocus([]);
        onHover(false);
        lastOpened.current = null;
        hoverD.current = null;
    })
  }, [date, onFocus, onHover])

  useEffect(() => {

    let query = "https://bluedot.onrender.com/articles/";

    if (date !== null)
    {
      const dateStr = date.format("YYYY-MM-DD");
      query = "https://bluedot.onrender.com/articles_date/?pubDate="+dateStr;
    }

    // load data
    console.log("fetch data");
    fetch(query)
      .then((res) => res.json())
      .then(function (rawData) {
        let hexData = [];
        const data = removeDuplicates(rawData);
        for (let i in data) {
          let hexItem = {
            article_id: data[i]._id,
            lat: data[i].coordinates[0],
            lng: data[i].coordinates[1],
            val: 5,
            pos: polar2Cartesian(
              data[i].coordinates[1],
              data[i].coordinates[0],
              100.0
            ),
            title: decode(data[i].title),
            description: decode(data[i].description),
            image: data[i].image_url,
            date: data[i].pubDate,
            link: data[i].link,
            source: data[i].link.split("/")[2],
            location: data[i].location,
          };
          hexData.push(hexItem);
        }
        kdTree.current = getKDTree(hexData, hexResolution);
        setPopData(hexData);
        loaded.current = true;
      })
  }, [hexResolution, date]);

  useEffect(() => {
    if (globeEl.current === undefined) return;
    const globe = globeEl.current;

    // Auto-rotate
    globe.controls().autoRotate = false;
    globe.controls().autoRotateSpeed = 0.1;
    globe.controls().addEventListener("start", () => {
        interacted.current = true;
    })

    globe.controls().addEventListener("change", () => {
      if (!loaded.current) return;
      if (!interacted.current) return;

      onStart(false);
      
      var cameraPos = globe.camera().position.clone();

      // WIP: ZOOM FEATURE
      /* var altitude = globe.toGeoCoords({
        x: cameraPos.x,
        y: cameraPos.y,
        z: cameraPos.z,
      }).altitude;

      if (altitude < 0.3) {
        setHexResolution(4);
      } else if (altitude < 1.0) {
        setHexResolution(3);
      } else {
        setHexResolution(2);
      } */

      cameraPos = cameraPos.normalize().multiplyScalar(100.0);
      cameraPos = globe.toGeoCoords({
        x: cameraPos.x,
        y: cameraPos.y,
        z: cameraPos.z,
      });

      var nearestPoint = kdTree.current.nearest(cameraPos, 1, [0.1]);
      var closestHex = null;

      if (nearestPoint.length > 0) closestHex = nearestPoint[0][0];

      if (closestHex !== null) {
        if (hoverD.current === null || closestHex.id !== hoverD.current.id) {
          hoverD.current = closestHex;

          const coords = globe.getCoords(closestHex.lat, closestHex.lng);

          setHexposUniform(globe, new Vector3(coords.x, coords.y, coords.z));

          const hexData = getHexData(globe);
          for (var i in hexData) {
            if (hoverD.current.id === hexData[i].h3Idx) {
              onFocus(hexData[i].points);
              onHover(false);
            }
          }
        }
      } else if (hoverD.current !== null) {
        setHexposUniform(globe, null);
        hoverD.current = null;
        onFocus([]);
        onHover(false);
      }
    });

    if (!interacted.current)
    {
      onStart(true);
    }

    globe.controls().addEventListener("end", () => {
      if (!loaded.current) return;
      if (hoverD.current !== null) {
        globe.pointOfView(
          { lat: hoverD.current.lat, lng: hoverD.current.lng },
          300
        );

        if (hoverD.current !== lastOpened.current)
          onHover(true);

        lastOpened.current = hoverD.current;

        // for (var i in getHexData(globe)) {
        //   if (hoverD.current.id === getHexData(globe)[i].h3Idx) {
        //     onFocus(getHexData(globe)[i].points);

        //     var hexpos = globe.getCoords(
        //       hoverD.current.lat,
        //       hoverD.current.lng
        //     );

        //     break; //Break?
        //   }
        // }
      }
    });

    // Add clouds sphere
    const CLOUDS_IMG_URL = "/clouds3.jpg"; // from https://github.com/turban/webgl-earth
    const CLOUDS_ALT = 0.002;
    const CLOUDS_ROTATION_SPEED = -0.006; // deg/frame

    new THREE.TextureLoader().load(CLOUDS_IMG_URL, (loadedCloudsTexture) => {
      if (globeEl.current !== undefined) {
        const scene = globeEl.current.scene();

        const getTextures = () =>
          new Promise((resolve, reject) => {
            const loader = new TextureLoader();
            DefaultLoadingManager.onLoad = () => resolve(textures);
            const textures = [
              "/earth4k.jpg",
              "/topology2k.jpg",
              "/water4k.jpg",
              "/roughness4k.jpg",
              "/night4k.jpg",
            ].map((filename) => loader.load(filename));
          });

        getTextures().then((result) => {
          fetch("https://bluedot.onrender.com/sunpos/")
            .then((res) => res.json())
            .then(function (data) {

              scene.children[1].intensity = 0.03;
              scene.children[2].intensity = 0;

              for (var i in scene.children[3].children[0].children[3].children) {
                scene.children[3].children[0].children[3].children[i].material[0] =
                  new THREE.MeshBasicMaterial();
              }

              cloudsTexture.current = loadedCloudsTexture;
              const clouds = new THREE.Mesh(
                new THREE.SphereGeometry(
                  globe.getGlobeRadius() * (1 + CLOUDS_ALT),
                  75,
                  75
                ),
                new createCloudMaterial(new Vector3(0.0), loadedCloudsTexture)
              );

              const sunpos = polar2Cartesian(data.sunpos[0], data.sunpos[1]);

              const directionalLight = new THREE.DirectionalLight(
                0xffffff,
                3.0
              );
              directionalLight.position.set(sunpos.x, sunpos.y, sunpos.z);
              

              scene.children[3].children[0].children[0].children[0].material =
                new THREE.MeshPhysicalMaterial({
                  map: result[0],
                  roughness: 1.0,
                  sheen: 1.0,
                  sheenRoughness: 0.6,
                  sheenColor: 0x60b5ff,
                  color: 0xffffff,
                  bumpMap: result[1],
                  bumpScale: 0.1,
                  specularIntensityMap: result[2],
                  roughnessMap: result[3],
                  specularIntensity: 1.0,
                  emissiveMap: result[4],
                  emissive: 0xffffff,
                  emissiveIntensity: 0.2,
                });

              const atmos = createGlowMesh(
                new THREE.SphereGeometry(globe.getGlobeRadius() * 1.2, 75, 75),
                sunpos
              );

              globe.scene().add(clouds);

              (function rotateClouds() {
                clouds.rotation.y += (CLOUDS_ROTATION_SPEED * Math.PI) / 180;
                requestAnimationFrame(rotateClouds);
              })();

              globe.pointOfView(
                { lat: data.sunpos[0], lng: data.sunpos[1], altitude: 2.25 },
                900
              );

              globe.scene().add(directionalLight);
              globe.scene().add(atmos);

              setIsLoading(false);
              
            });
        });
      }
    });
  }, [onFocus, onHover, onStart]);

  var globeWidth = dimensions.width;
  if (!isMobile) globeWidth *= 1.3;

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      {(
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              paddingLeft: isMobile ? "0vw" : "15vw",
              zIndex: 100,
            }}
            className={isLoading ? "solid" : "fade"}
          >
            <PuffLoader
              color={"#0D5299"}
              loading={true}
              size={"70vw"}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </>
      )}
      <Globe
        ref={globeEl}
        showAtmosphere={false}
        backgroundImageUrl="/night-sky.png"
        hexBinPointsData={popData}
        hexBinPointWeight="val"
        hexAltitude={(d) => Math.min(0.25, Math.log(1.0 + d.sumWeight * 0.002))}
        hexBinResolution={hexResolution}
        hexSideColor={(d) => weightColor(d.sumWeight)}
        hexTopColor={(d) => weightColor(d.sumWeight)}
        hexBinMerge={true}
        width={globeWidth}
        height={dimensions.height}
      />
    </div>
  );
};

const MemoizedGlobeComponent = memo(GlobeComponent);

export default MemoizedGlobeComponent;
