import { React, useState, useEffect } from "react";
import MemoizedGlobeComponent from "./GlobeComponent";
import ReactGA from "react-ga4";
import Popup from "./Popup";
import {SwipeableEdgeDrawer, HelpSwipeableEdgeDrawer} from "./SwipeableEdgeDrawer";
import "./App.css";
import TwitterIcon from '@mui/icons-material/Twitter';
import Fab from '@mui/material/Fab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import dayjs from "dayjs"
import HistoryIcon from '@mui/icons-material/History';


ReactGA.initialize("G-B8Y3YHYHB1");



function App() {
  const [articles, setArticles] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(false);
  const [date, setDate] = useState(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const newTheme = createTheme({
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: '#bbdefb',
            borderRadius: 10,
            borderWidth: 0,
            borderColor: '#2196f3',
            border: '0px solid',
            backgroundColor: 'rgba(3, 147, 252, 1.0)',
          }
        }
      }
    }
  })
  
  return (

    <div className="App">
      <header className="App-header" style={{ padding: 0 }}>
        <img
          className="App-logo"
          src="/logo.png"
          alt="blue dot news logo"
        ></img>
        
      </header>
      { !isMobile &&
      <div className="date-picker">
        <ThemeProvider theme={newTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      
            <DatePicker slotProps={{
                          field: { clearable: true },
                          textField: { InputProps: { notched: false} }
                        }}
                        slots={{ openPickerIcon: HistoryIcon }}
                        defaultValue={dayjs()}
                        disableFuture={true}
                        minDate={new dayjs("2023/10/11")}
                        onChange={(newValue) => setDate(newValue)}/>

          </LocalizationProvider>
        </ThemeProvider>
      </div>
      }
      {
        isMobile ? (
        articles.length ?
        <SwipeableEdgeDrawer
          results={articles}
          openAuto={open}
          setOpenAuto={setOpen}
          child={articles.length > 0 && <Popup isMobile={isMobile} articles={articles} />}
        /> :
        <HelpSwipeableEdgeDrawer
          results={articles}
          openAuto={start}
          setOpenAuto={setStart}
        />
      ) : 
        <Popup articles={articles} isMobile={isMobile} isStart={start}/>
      }
      { !isMobile &&
      <Fab color="primary" aria-label="twitter" sx={fabStyle} href="https://twitter.com/palebluenews">
        <TwitterIcon/>
      </Fab>}
      <MemoizedGlobeComponent
        isMobile={isMobile}
        onFocus={setArticles}
        onHover={setOpen}
        onStart={setStart}
        date={date}
      />
    </div>

  );
}

export default App;
