import React from "react";
import "./Popup.css";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";

const Popup = ({articles, isMobile, isStart}) => {
  const shortenString = (str) =>
    str.split(" ").slice(0, 20).join(" ") +
    (str.split(" ").length > 20 ? "..." : "");

  return (
    isStart ?
    <div className="popup info">
    <Card
              color="primary"
              invertedColors
              orientation="vertical"
              size="md"
              variant="solid"
              sx={{ width: 320 }}
            >
    <Typography level="title-md">
            Welcome to paleblue.news
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            World news from a global perspective.
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            Scroll around and explore today's news from all over the globe.
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            We believe that this view provides important global context that is lost when browsing traditional news websites.
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            Try the time travel feature to see the news for a date in the past.
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            This concept is inspired by the <a href="https://en.wikipedia.org/wiki/Overview_effect">overview effect</a>.
            The name is a reference to <a href="https://en.wikipedia.org/wiki/Pale_Blue_Dot">Pale Blue Dot</a>
          </Typography>
          <Typography
            variant="plain" color="netural" level="body-sm"
          >
            <a href="mailto:contact@paleblue.news">
              Email us
            </a>
          </Typography>
          </Card></div> :
    <div className="popup articles">
      <Stack spacing={2}>
        {articles.map((item, index) => (
          <a href={item.link} key={item.article_id} target="_blank" rel="noreferrer">
            <Card
              color="primary"
              invertedColors
              orientation="vertical"
              size="md"
              variant="solid"
              sx={{ width: 320 }}
            >
              {(!isMobile || index > 0) && item.image != null && (
                <CardOverflow>
                  <AspectRatio ratio="2">
                    <img
                      src={item.image}
                      srcSet={item.image}
                      loading="lazy"
                      alt=""
                    />
                  </AspectRatio>
                </CardOverflow>
              )}
              <CardContent>
                <Typography level="title-md">{item.title}</Typography>
                <Typography variant="plain" color="netural" level="body-sm">
                  {item.description && shortenString(item.description)}
                </Typography>
                <Typography
                  level="body-xs"
                  fontWeight="md"
                  textColor="text.secondary"
                >{item.source}</Typography>
              </CardContent>
              <CardOverflow
                variant="soft"
                sx={{ bgcolor: "background.level1" }}
              >
                <Divider inset="context" />
                <CardContent orientation="horizontal">
                  <Typography
                    level="body-xs"
                    fontWeight="md"
                    textColor="text.secondary"
                  >
                    {item.location}
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    level="body-xs"
                    fontWeight="md"
                    textColor="text.secondary"
                  >
                    {new Date(item.date).toLocaleDateString("en-GB")}
                  </Typography>
                </CardContent>
              </CardOverflow>
            </Card>
          </a>
        ))}
      </Stack>
    </div>
  );
};

export default Popup;
