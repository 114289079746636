import { BackSide, BufferAttribute, Color, Mesh, ShaderMaterial } from "three";

const THREE = window.THREE
  ? window.THREE // Prefer consumption from global THREE, if exists
  : {
      BackSide,
      BufferAttribute,
      Color,
      Mesh,
      ShaderMaterial,
    };

//
const fragmentShader = `
uniform vec3 color;
uniform float coefficient;
uniform float power;
uniform vec3 sunpos;
varying vec3 vVertexNormal;
varying vec3 vVertexWorldPosition;
void main() {
  vec3 worldCameraToVertex = vVertexWorldPosition - cameraPosition;
  vec3 viewCameraToVertex	= (viewMatrix * vec4(worldCameraToVertex, 0.0)).xyz;
  viewCameraToVertex = normalize(viewCameraToVertex);

  float intensity	= 2.0*pow(
    coefficient + dot(vVertexNormal, viewCameraToVertex),
    power
  );
  float alpha = mix(
    dot(normalize(cameraPosition), normalize(sunpos)),
    max(0.0,dot(normalize(vVertexWorldPosition), normalize(sunpos))),
    sqrt(sqrt(1.0-(max(0.0,dot(normalize(cameraPosition), normalize(sunpos)))))))*intensity;

  gl_FragColor = vec4(
    color,
    alpha
  );
}`;

const vertexShader = `
varying vec3 vVertexWorldPosition;
varying vec3 vVertexNormal;
void main() {
  vVertexNormal	= normalize(normalMatrix * normal);
  vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
  gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const defaultOptions = {
  backside: true,
  coefficient: 0.1,
  color: "lightskyblue",
  size: 2,
  power: 4,
};

// Based off: http://stemkoski.blogspot.fr/2013/07/shaders-in-threejs-glow-and-halo.html
export function createGlowMaterial(sunpos, coefficient, color, power) {
  return new THREE.ShaderMaterial({
    depthWrite: false,
    fragmentShader,
    transparent: true,
    uniforms: {
      sunpos: {
        value: sunpos,
      },
      coefficient: {
        value: coefficient,
      },
      color: {
        value: new THREE.Color(color),
      },
      power: {
        value: power,
      },
    },
    vertexShader,
  });
}

export function createGlowGeometry(geometry, size) {
  // expect BufferGeometry
  const glowGeometry = geometry.clone();

  // Resize vertex positions according to normals
  const position = new Float32Array(geometry.attributes.position.count * 3);
  for (let idx = 0, len = position.length; idx < len; idx++) {
    const normal = geometry.attributes.normal.array[idx];
    const curPos = geometry.attributes.position.array[idx];
    position[idx] = curPos + normal * size;
  }
  glowGeometry.setAttribute("position", new THREE.BufferAttribute(position, 3));

  return glowGeometry;
}

export function createGlowMesh(geometry, sunpos, options = defaultOptions) {
  const { coefficient, color, size, power } = options;

  const glowGeometry = createGlowGeometry(geometry, size);
  const glowMaterial = createGlowMaterial(sunpos, coefficient, color, power);

  glowMaterial.side = THREE.BackSide;

  return new THREE.Mesh(glowGeometry, glowMaterial);
}
