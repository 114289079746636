import * as React from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import TwitterIcon from '@mui/icons-material/Twitter';
import Fab from '@mui/material/Fab';

const drawerBleeding = 135;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function mode(arr) {
  const store = {}
  arr.forEach((item) => store[item.location] ? store[item.location] += 1 : store[item.location] = 1)
  return Object.keys(store).sort((a, b) => store[b] - store[a])[0]
}

function SwipeableEdgeDrawer(props) {
  const { window, child, results, setOpenAuto } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenAuto(false);
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const handleSwipeDown = (e) => {
    // Prevent the drawer from closing when swiping down

    let scrollTop = document.querySelector(".popup").scrollTop;
  
    if (scrollTop > 10)
      e.stopPropagation();
  };
  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
            visibility: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        allowSwipeInChildren={true}
        anchor="bottom"
        open={open}// || openAuto}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        disablePortal
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ height: "135px", p: 2, color: "text.secondary" }}>
            {`${results.length} results near ${mode(results).slice(
                  0,
                  20
                )}...`}
          </Typography>
          <div
            onTouchMove={handleSwipeDown}
            style={{
              position: "absolute",
              top: "50px",
              height: "58vh",
              width: "100%",
            }}
          >
            {child}
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

function HelpSwipeableEdgeDrawer(props) {
  const { window, openAuto, setOpenAuto } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenAuto(false);
    setOpen(newOpen);
  };

  const drawerBleedingSmall = 60;

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(70% - ${drawerBleedingSmall}px)`,
            overflow: "visible",
            visibility: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        allowSwipeInChildren={true}
        anchor="bottom"
        open={open || openAuto}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleedingSmall}
        disableSwipeToOpen={false}
        disablePortal
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleedingSmall,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ height: "50px", p: 2, color: "text.secondary" }}>
            Explore the news...
          </Typography>
        </StyledBox>

        <div className="drawerInfo"
          style={{
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Typography variant="h5">
            Welcome to <span style={{ color: "rgba(3, 147, 252, 0.9)" }}>paleblue.news</span>
          </Typography>
          <Typography
            variant="p"
            style={{ paddingRight: 8, paddingLeft: 8, textAlign: "center" }}
          >
            World news from a global perspective.
          </Typography>
          <Typography
            variant="p"
            style={{ paddingRight: 8, paddingLeft: 8, textAlign: "center" }}
          >
            Scroll around and explore today's news from all over the globe.
          </Typography>
          <Typography
            variant="p"
            style={{ paddingRight: 8, paddingLeft: 8, textAlign: "center" }}
          >
            We believe that this view provides important global context that is lost when browsing traditional news websites.
          </Typography>
          <Typography
            variant="p"
            style={{ paddingRight: 8, paddingLeft: 8, textAlign: "center" }}
          >
            This concept is inspired by the <a href="https://en.wikipedia.org/wiki/Overview_effect"><span style={{ color: "rgba(3, 147, 252, 0.9)" }}>overview effect</span></a>.
            The name is a reference to <a href="https://en.wikipedia.org/wiki/Pale_Blue_Dot"><span style={{ color: "rgba(3, 147, 252, 0.9)" }}>Pale Blue Dot</span></a>
          </Typography>
          <Typography
            variant="p"
            style={{ paddingRight: 8, paddingLeft: 8, textAlign: "center" }}
          >
            <a href="mailto:contact@paleblue.news">
              <span style={{ color: "rgba(3, 147, 252, 0.9)" }}>Email us</span>
            </a>
          </Typography>
          <Typography></Typography>
          <Typography></Typography>
          <Fab color="primary" aria-label="twitter" sx={fabStyle} href="https://twitter.com/palebluenews">
            <TwitterIcon/>
          </Fab>
        </div>

      </SwipeableDrawer>
    </Root>
  );
}

export {
  SwipeableEdgeDrawer,
  HelpSwipeableEdgeDrawer
};
