import { MeshLambertMaterial } from "three";

// Based off: http://stemkoski.blogspot.fr/2013/07/shaders-in-threejs-glow-and-halo.html
export function createCloudMaterial(hexpos, texture) {
  var material = new MeshLambertMaterial({
    map: texture,
    alphaMap: texture,
    transparent: true,
  });

  material.userData.hexpos = { value: hexpos };

  material.onBeforeCompile = function (shader) {
    shader.uniforms.hexpos = material.userData.hexpos;

    shader.vertexShader =
      "varying vec3 vVertexWorldPosition;\n" + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace(
      "vViewPosition = - mvPosition.xyz;",
      `
      vViewPosition = - mvPosition.xyz;
      vVertexWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      
    
    `
    );

    shader.fragmentShader =
      "varying vec3 vVertexWorldPosition;\nuniform vec3 hexpos;\n" +
      shader.fragmentShader;

    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <map_fragment>",
      `
      #include <map_fragment>
      float a = diffuseColor.a;
      diffuseColor =  vec4( 1.0,1.0,1.0, min(1.0, a)) ;
    `
    );

    /* shader.fragmentShader = shader.fragmentShader.replace(
      "#include <map_fragment>",
      `
      #include <map_fragment>
      float fade = min(1.0, length(hexpos));
      float dist = distance(normalize(vVertexWorldPosition), normalize(hexpos));
      float dist2 = distance(normalize(hexpos), normalize(cameraPosition));
      fade = fade*(1.0-min(1.0,pow(dist2*10.0,3.0)));
      float a = diffuseColor.a*mix(1.0, min(1.0, pow(dist*2.0,3.0)),fade );// + vec4(1.0,1.0,1.0,pow(1.0-min(1.0,dist*5.0),2.0);
      diffuseColor =  vec4( 1.0,1.0,1.0, min(1.0, a)) ;
    `
    ); */
    shader.fragmentShader = shader.fragmentShader.replace(
      "#include <emissivemap_fragment>",
      `
      #include <emissivemap_fragment>
      //totalEmissiveRadiance += vec3(1.0,1.0,1.0)*pow(1.0-min(1.0,dist*5.0),2.0);
    `
    );
  };

  return material;
}
